import { graphql } from 'gatsby';
import BlogComponent from '@lekoarts/gatsby-theme-minimal-blog-core/src/templates/blog-query';
export default BlogComponent;

export const query = graphql`
  query ($formatString: String!) {
    allPost(
      sort: { fields: date, order: DESC }
      filter: { tags: { elemMatch: { name: { ne: "draft" } } } }
    ) {
      nodes {
        slug
        title
        date(formatString: $formatString)
        excerpt
        timeToRead
        description
        banner {
          childImageSharp {
            fluid(maxWidth: 1000, maxHeight: 500, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        tags {
          name
          slug
        }
      }
    }
  }
`;
